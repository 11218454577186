
import { GROUP_TYPE_OFFERING } from '~/vars/api';

import ProductFavs from './ProductFavs.vue';
import ProductDependency from './ProductDependency.vue';
import MarketProductHeader from '../MarketProductHeader.vue';
import ProductDelistBtn from '../buttons/ProductDelistBtn.vue';
import BaseCurrencyAmount from '~/components/base/BaseCurrencyAmount.vue';
import ProductCardPriceContent from '~/components/market/base/ProductCardPriceContent.vue';
export default {
  components: {
    ProductFavs,
    ProductDelistBtn,
    ProductDependency,
    BaseCurrencyAmount,
    MarketProductHeader,
    ProductCardPriceContent,
  },
  props: {
    offering: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      default: '250',
    },
    height: {
      type: String,
      default: '250',
    },
    cover: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tab: 0,
      overflowed: false,
      titleToolTipWidth: 0,
    };
  },
  computed: {
    productId() {
      return this.tokensGroup.type === GROUP_TYPE_OFFERING &&
        this.tokensGroup.offering.status !== 'closed'
        ? this.tokensGroup.offeringid
        : this.tokensGroup.offeringid;
    },
    GROUP_TYPE_OFFERING() {
      return GROUP_TYPE_OFFERING;
    },
    tabs() {
      return this.offering.contract.issuernick ==
        this.tokensGroup.offering.ownernick
        ? [this.$t('market.product.card.both')]
        : [
            this.$t('market.product.card.creator'),
            this.$t('market.product.card.seller'),
          ];
    },
    productImg() {
      let image;
      if (this.offering.contract.image.charAt(0) === '[')
        image = JSON.parse(this.offering.contract.image).map(
          (item) => item + (item.endsWith('?alt=media') ? '' : '?alt=media'),
        )[0];
      else
        image =
          this.offering.contract.image +
          (this.offering.contract.image.endsWith('?alt=media')
            ? ''
            : '?alt=media');
      return image;
    },
    isOwner() {
      return (
        this.$auth.loggedIn &&
        this.tokensGroup.offering.ownernick === this.$auth.user.data.nick
      );
    },
    offeringPrice() {
      if (this.tokensGroup.offering.allowbuy) {
        return this.tokensGroup.offering.buyprice;
      }
      return this.tokensGroup.offering.minimbid
        ? this.tokensGroup.offering.minimbid
        : this.offering.groups[0].offering.initialbidprice;
    },
    tokensGroup() {
      return this.offering.groups[0];
    },
    showTitleToolTip: {
      get() {
        return this.overflowed;
      },
      set(value) {
        this.overflowed = value;
      },
    },
  },

  methods: {
    onProductClicked() {
      this.$router.push(
        this.localePath({
          name: 'market-product-id',
          params: {
            id: this.productId,
          },
        }),
      );
    },
    checkShowTitleToolTip() {
      const titleElement = document.querySelector(`#${this.productId}-title`);
      const cardElement = document.querySelector(`#${this.productId}-card`);

      this.titleToolTipWidth = cardElement.clientWidth - 10;
      this.showTitleToolTip =
        titleElement.scrollHeight > titleElement.clientHeight;
    },
  },
  mounted() {},
};
