
import { EventBus } from '~/components/eventBus/EventBus';
import { convertMiliseconds } from '~/utils/functions';
import { GROUP_TYPE_OFFERING, MINUTE } from '~/vars/api';
export default {
  components: {},
  mixins: [],
  props: {
    offering: {
      type: Object,
      required: true,
    },
    tokensGroup: {
      type: Object,
      required: true,
    },
    assignedOrder: {
      type: Object,
    },
    isShowingAsSingleProduct: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      remaining: 0,
      isNearToStart: false,
      ended: false,
      interval: null,
    };
  },
  computed: {
    isOffering() {
      return this.tokensGroup.type === GROUP_TYPE_OFFERING;
    },
    remainingToHms() {
      if (this.remaining.d > 0)
        return this.$t(
          `base.timer.days.${this.remaining.d > 1 ? 'plural' : 'singular'}`,
          { unit: this.remaining.d },
        );
      if (this.remaining.h > 0)
        return `${this.$t(
          `base.timer.hours.${this.remaining.h > 1 ? 'plural' : 'singular'}`,
          { unit: this.remaining.h },
        )}`;
      if (this.remaining.m > 0)
        return this.$t(
          `base.timer.minutes.${this.remaining.m > 1 ? 'plural' : 'singular'}`,
          { unit: this.remaining.m },
        );
      if (this.remaining.s >= 0)
        return this.$t(
          `base.timer.seconds.${this.remaining.s > 1 ? 'plural' : 'singular'}`,
          { unit: this.remaining.s },
        );
      else return this.$t('base.ending');
    },
    endDatestamp() {
      return (
        this.tokensGroup.offering.status === 'open' &&
        this.tokensGroup.offering.enddatestamp < new Date().getTime()
      );
    },
    noBidsAuction() {
      return this.tokensGroup.offering.status === 'nobids';
    },
    closedAuction() {
      return this.tokensGroup.offering.status === 'closed';
    },
    offeringStatusText() {
      return this.$t(
        `market.product.actions.offering.${
          this.tokensGroup.offering.allowbid ? 'auction' : 'buy'
        }.${
          this.tokensGroup.offering.allowbid || !this.endDatestamp
            ? this.tokensGroup.offering.status
            : 'closed'
        }`,
      );
    },
    hasOrder() {
      return this.assignedOrder && this.assignedOrder.id;
    },
    orderPending() {
      return [
        'accepted',
        'payment_intent_created',
        'payment_intent_success',
      ].includes(this.assignedOrder.status);
    },
    orderStatusText() {
      return this.$t(
        `market.product.actions.order.${this.assignedOrder.status}`,
      );
    },
    isOwner() {
      return (
        this.isOffering &&
        this.$auth.loggedIn &&
        this.tokensGroup.offering.ownernick === this.$auth.user.data.nick
      );
    },
    isVisible() {
      return this.isOffering && this.tokensGroup.offering.visible === true;
    },
    validationStatus() {
      if (this.isOffering)
        return this.tokensGroup.offering.visible === null
          ? 'pending'
          : this.tokensGroup.offering.visible === false
          ? 'not_validated'
          : 'validated';
      return '';
    },
  },
  watch: {},
  methods: {
    setCountDown() {
      this.interval = setInterval(() => {
        const now = new Date().getTime();
        if (this.tokensGroup.offering.enddatestamp <= now) {
          EventBus.$emit('freeze-offering', {
            id: this.tokensGroup.offeringid,
          });
          clearInterval(this.interval);
        } else if (
          now >=
          this.tokensGroup.offering.enddatestamp - 5 * 60 * MINUTE
        ) {
          this.isNearToStart = true;
        }
        this.remaining = convertMiliseconds(
          this.tokensGroup.offering.enddatestamp - now,
        );
      }, 1000);
    },
  },
  /*
   ** Lyfecycle hooks
   */
  created() {},
  mounted() {
    this.ended = false;
    this.remaining = convertMiliseconds(
      this.tokensGroup.offering.enddatestamp - new Date().getTime(),
    );
    this.setCountDown();
  },
  updated() {},
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
