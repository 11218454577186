
import BaseBtn from '~/components/base/BaseBtn.vue';
import { USER_ACTION_PRODUCT_FAV } from '~/vars/api';
export default {
  components: {
    BaseBtn,
  },
  props: {
    favCounter: {
      type: Number,
      default: 0,
    },
    text: {
      type: Boolean,
      default: false,
    },
    depressed: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: 'white',
    },
    textColor: {
      type: String,
      default: 'text-root',
    },
  },
  computed: {
    USER_ACTION_PRODUCT_FAV() {
      return USER_ACTION_PRODUCT_FAV;
    },
    favorites() {
      return `${this.favCounter}`;
    },
    color() {
      return this.text ? this.textColor : this.bgColor;
    },
  },
};
