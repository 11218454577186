
import { handleErrors } from '~/utils/functions';
import { EventBus } from '~/components/eventBus/EventBus';
import { SECOND, USER_ACTION_PRODUCT_DELIST } from '~/vars/api';
import { showDelistOfferingModal } from '~/vars/store/actions';
export default {
  props: {
    tokenSerieId: {
      type: String,
      required: true,
    },
    tokensGroup: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    USER_ACTION_PRODUCT_DELIST() {
      return USER_ACTION_PRODUCT_DELIST;
    },
  },
  methods: {
    async onDelistProductClicked() {
      this.$store.dispatch(`market/${showDelistOfferingModal}`, {
        tokenSerieId: this.tokenSerieId,
        tokensGroup: this.tokensGroup,
      });
    },
  },
};
