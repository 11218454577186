
import {
  GROUP_TYPE_AVAILABLE,
  GROUP_TYPE_INVOLVED,
  GROUP_TYPE_OFFERING,
  GROUP_TYPE_REDEEMED,
  PRODUCT_CREATOR,
  PRODUCT_SELLER,
} from '~/vars/api';
import ProductUserRelated from './ProductUserRelated.vue';
export default {
  components: { ProductUserRelated },
  props: {
    product: {
      type: Object,
      required: true,
    },
    tokensGroup: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
    },
    showCreator: {
      type: Boolean,
      default: true,
    },
    tab: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: 'transparent',
    },
  },
  data() {
    return {
      user: 1,
    };
  },
  computed: {
    PRODUCT_SELLER() {
      return PRODUCT_SELLER;
    },
    PRODUCT_CREATOR() {
      return PRODUCT_CREATOR;
    },
    isInvolved() {
      return this.tokensGroup.type === GROUP_TYPE_INVOLVED;
    },
    isDeleted() {
      return this.tokensGroup.type === GROUP_TYPE_REDEEMED;
    },
    owner() {
      if (this.tokensGroup.type === GROUP_TYPE_INVOLVED) return null;
      return this.tokensGroup.type === GROUP_TYPE_OFFERING
        ? this.tokensGroup.offering.ownernick
        : this.$auth.loggedIn
        ? this.$auth.user.data.nick
        : null;
    },
    creator() {
      return this.product.contract.issuernick;
    },
    ownerDate() {
      return this.tokensGroup.nftlist.length > 0
        ? this.tokensGroup.nftlist[0].ownerdatestamp
        : null;
    },
    creatorDate() {
      return this.product.contract.datestamp;
    },
  },
};
