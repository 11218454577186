
import { GROUP_TYPE_OFFERING } from '~/vars/api';
import { EventBus } from '~/components/eventBus/EventBus';
import Remaining from '../single/Remaining.vue';
export default {
  components: { Remaining },
  props: {
    offering: {
      type: Object,
      required: true,
    },
    tokensGroup: {
      type: Object,
      required: true,
    },
    assignedOrder: {
      type: Object,
    },
    isShowingAsSingleProduct: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    auction() {
      return this.offering.groups.find(
        (item) => item.type === GROUP_TYPE_OFFERING,
      );
    },
    offeringPrice() {
      if (this.auction.offering.allowbuy) {
        return this.auction.offering.buyprice;
      }
      return this.tokensGroup.offering.minimbid
        ? this.tokensGroup.offering.minimbid
        : this.tokensGroup.offering.initialbidprice;
    },
  },
  methods: {},
};
