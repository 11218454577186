
import UserHeader from '~/components/user/UserHeader.vue';
import UserMixinHandler from '~/components/user/UserMixinHandler.vue';
export default {
  components: { UserHeader },
  mixins: [UserMixinHandler],
  props: {
    userNick: {
      type: String,
      required: true,
    },
    dependency: {
      type: String,
    },
    date: {
      type: Number,
    },
    size: {
      type: Number,
      default: 50,
    },
    nick: {
      type: Boolean,
      default: false,
    },
    showUserModal: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    info() {
      return this.dependency && this.date
        ? this.$t(`market.product.dependency.${this.dependency}`, {
            date: this.formatedDate,
          })
        : null;
    },
    formatedDate() {
      return this.date !== null && typeof this.date !== 'undefined'
        ? this.$moment(this.date).format('DD-MM-YYYY')
        : '';
    },
  },
  methods: {},
};
